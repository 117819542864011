/**
* Bring in base css and theme for tippy js  popups
*/
@import "../../node_modules/tippy.js/dist/tippy.css";
@import "../../node_modules/tippy.js/themes/light.css";

/* Use css shipped with formio package so we use correct version instead of @latest from unpkg.com */
@import "~formiojs/dist/formio.full.min.css";
/**
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://unpkg.com/tailwindcss/dist/base.css
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/base";
 */
@tailwind base;

/**
 * This injects any component classes registered by plugins.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */
 @import "tailwindcss/components";
/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

/* CHECKBOX TOGGLE SWITCH */
.form-switch {
    @apply relative select-none w-12 mr-2 leading-normal;
}
.form-switch-checkbox {
    @apply hidden;
}
.form-switch-label {
    @apply block overflow-hidden cursor-pointer bg-white border rounded-full h-8 shadow-inner bg-gray-300;
    transition: background-color 0.2s ease-in;
}
.form-switch-label:before {
    @apply absolute block bg-white inset-y-0 h-6 w-6 border rounded-full;
    left: 10%;
    top: 11%;
    content: "";
    transition: all 0.2s ease-in;
}

.form-switch-checkbox:checked + .form-switch-label {
    @apply bg-blue-700 shadow-none;
}
.form-switch-checkbox:checked + .form-switch-label:before {
    @apply inset-y-0;
    left: unset;
    right: 10%;
    top: 11%;
}

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */
@tailwind utilities;

/**
*This pulls in the css for the vue-select component
*/
@import "../../node_modules/vue-select/dist/vue-select.css";

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */

.field-required:after {
    content: "" !important;
}

/* .apiabroad-form-group-error .form-input {
    @apply form-input-error;
} */

.formio-component label > span {
    @apply text-gray-600;
}

.formio-component.label-light label > span {
    @apply font-normal text-base;
}

.formio-component-htmlelement * {
    @apply text-gray-600;
}

.formio-component-htmlelement h1,
.formio-component-htmlelement h2,
.formio-component-htmlelement h3,
.formio-component-htmlelement h4,
.formio-component-htmlelement h5,
.formio-component-htmlelement h6 {
    @apply text-gray-700;
}

.apiabroad-svg * {
    color: #000;
}
a.api-link:focus,
a.api-link:hover {
    text-decoration: underline;
    color: #007f80;
}
.api-link {
    color: #007f80;
    font-weight: 600;
    text-decoration: inherit;
}
/**
* the following css styles the label next to the select box on the
* host university internship form
*/
.internship-rank {
    display: flex;
    align-items: center;
}
.internship-rank > label {
    margin-right: 30px;
}
.internship-rank > div.choices {
    flex: 1;
}
@media (max-width: 600px) {
    img.logo {
        max-width: 155px;
    }
}
/**
* bullet list styling on upload files
*/
.fileUploadField div.ui.grid {
    grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
}
.fileUploadField li {
    list-style: none;
    display: flex;
}
.fileUploadField .bg-error {
    color: red;
}
.fileUploadField li:before {
    content: "✓";
    color: green; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em;
    margin-right: 1em;
}

.formio-component-form.programslist-virtual
    .formio-component-programSession
    > fieldset
    span:before,
.formio-component-panel1.programslist-virtual
    .formio-component-programSession2
    > fieldset
    span:before {
    content: "virtual";
    @apply mr-4 bg-blue-500 px-2 inline-block text-center py-1 leading-none text-white uppercase whitespace-nowrap rounded-full text-1xs;
}

/* Make radio buttons not be so far apart and keep them inline on small devices */
.formio-radio > fieldset > div {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.apiabroad-error-message {
    color: rgba(209, 19, 19, var(--text-opacity));
}
button:disabled,
button[disabled] {
    cursor: not-allowed;
    opacity: 0.33;
}

.help-text-component {
    margin-top: 0 !important;
}

.toggle-checkbox:checked {
    right: 0;
    border-color: #4354a8;
}

.toggle-checkbox:checked + .toggle-label {
    background: #4354a8;
}

.country-code-columns {
    grid-template-columns: 75px 1fr;
}

@media only screen and (max-width: 1199px) {
    .country-code-columns > div {
        grid-column: unset;
    }
}

#approval-status-id {
    @apply text-teal-100;
}

.country-code-columns > div > div.formio-component-countryCode {
    margin-top: 2px;
}

/**
*override vue-tailwind select style to mach our form components style
*/
.v-select > .vs__dropdown-toggle {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #e5e7e8;
    border-width: 1px;
    border-radius: 0.25rem;
    padding-top: 0.375rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
}
.v-select > .vs__dropdown-toggle,
.vs__selected-options {
    max-height: 2.5rem;
    overflow: hidden;
}
.bg-error-100 > .vs__dropdown-toggle {
    --bg-opacity: 1;
    background-color: #faeeed;
    background-color: rgba(250, 238, 237, var(--bg-opacity));
}

/**
*Data tables v-select style
*/
.filters .vs__search::placeholder {
    color: #a6abb6;
}
.filters .vs__dropdown-toggle {
    min-height: 2.5rem;
}
.filters .vs__dropdown-option {
    line-height: 2.5rem;
}
.filters .vs__selected {
    background-color: #ffffff;
    border: none;
}

/* Table Styles */
.datatable {
    @apply w-full;
    @apply text-sm text-gray-600;
}
  .datatable tr {
    @apply text-xs font-semibold text-left;
}
.datatable th,
.datatable td {
    @apply px-4 py-2;
}

.card-row {
    @apply relative block px-4 pb-4 mb-4;
    @apply bg-white border-t border-gray-200 shadow;
}
.card-section {
    @apply block mt-4;
}
.card-data {
    @apply block mt-4 flex justify-between items-baseline;
}
.card-label {
    @apply text-xs font-semibold tracking-widest text-gray-500 uppercase;
}
.card-controls {
    @apply block mt-4 absolute top-0 right-0 flex justify-between items-baseline;
}
.card-controls-container {
    @apply absolute top-0 right-0 block;
    @apply text-center;
}

.dropdown-container {
    @apply absolute right-0 z-50 hidden w-48;
    @apply bg-white shadow-lg top-full;
}
.dropdown-item {
    @apply block px-4 py-3;
    @apply font-normal text-gray-600;
}
.dropdown-item:hover {
    @apply bg-blue-300 text-gray-700 no-underline;
}

@screen md {
    .card-row {
        @apply table-row bg-transparent shadow-none p-0 m-0;
    }
    .card-section {
        @apply table-cell py-4 px-3;
    }
    .card-data {
        @apply table-cell py-4 px-3;
    }
    .card-label {
        @apply hidden mr-0;
    }
    .card-controls {
        @apply table-cell py-4 px-3 static;
    }
    .card-controls-container {
        @apply p-4 table-cell static;
    }
}

.student-link {
    color: #000;
}
.student-link:focus,
.student-link:hover {
    text-decoration: underline;
    color: #007f80;
}
/* End Table Styles */

/* div divider used in onboarding step 2 */
.divider-onboarding {
    height: 1px;
    margin: 22px 18px 24px 0;
    background-color: #e5e7e8;
}

/* Here are custom utilities thatare changed with upgrade
from Tailwind v1 to v2  */


input.form-input[type="text"] {
    @apply
    rounded
    border-gray-300
    focus:ring-0
    focus:border-blue-700
    focus:bg-blue-100;
}
input.form-input[type="number"] {
    @apply
    rounded
    border-gray-300
    focus:ring-0
    focus:border-blue-700
    focus:bg-blue-100
    shadow-sm;
}
input.form-input[type="email"] {
    @apply
    rounded
    border-gray-300
    focus:ring-0
    focus:border-blue-700
    focus:bg-blue-100
    shadow-sm;
}
input.form-input[type="password"] {
    @apply
    rounded
    border-gray-300
    focus:ring-0
    focus:border-blue-700
    focus:bg-blue-100
    shadow-sm;
}
.form-select {
    @apply
    rounded
    border-gray-300
    focus:bg-blue-100
    focus:border-blue-700;
}
.form-select:focus {
    @apply
    rounded
    focus:ring-0
    focus:bg-blue-100
    focus:border-blue-700;
}
input.form-checkbox[type="checkbox"] {
    @apply
    rounded
    border-gray-300
    focus:outline-none
    focus:ring
    focus:ring-opacity-30
    focus:ring-sky-500
    focus:border-sky-400
    focus:ring-offset-0;
}
input.form-checkbox:checked[type="checkbox"] {
    @apply rounded border-blue-700 text-blue-700 focus:border-sky-400;
}
input.form-radio[type="radio"]{
    @apply
    border-gray-300
    focus:outline-none
    focus:ring
    focus:ring-opacity-30
    focus:ring-sky-500
    focus:ring-offset-0;
}
input.form-radio:checked[type="radio"]{
    @apply text-blue-700 border-blue-700 focus:border-sky-400;
}
img.iconwarning {
    width: 16px;
    height: 16px;
    margin: 13px 14px 13px 0;
    object-fit: contain;
  }
